<template lang="pug">
  .widget
    v-animate
      hooper(infiniteScroll)
        slide
          img.slide-img(src="@/assets/img/slider-01.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-02.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-03.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-04.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-05.jpg")
        hooper-navigation(slot="hooper-addons")
          template(slot="hooper-prev")
            img.w-slider__controls(src="@/assets/svg/slider-control.svg")
          template(slot="hooper-next")
            img.w-slider__controls(src="@/assets/svg/slider-control.svg")
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
  name: 'SliderWidget',

  components: {
    Hooper,
    Slide,
    HooperNavigation
  },

  data: () => ({
    hooperSettings: {
      itemsToShow: 1,
      centerMode: true
    }
  })
}
</script>

<style lang="scss">
</style>
