<template lang="pug">
  .widget.timing-widget
    img.timing-widget__decor(src="@/assets/illustrations/flower-branch.svg")
    .title Тайминг дня
    .timing-widget__item(
      v-for="(item, index) in items"
      :key="index")
      .timing-widget__item-time {{ item.time }}
      .timing-widget__item-label {{ item.label }}
</template>

<script>
export default {
  name: 'TimingWidget',

  data: () => ({
    items: [
      { time: '13:30 – 14:00', label: 'Ожидание гостей' },
      { time: '14:00 – 14:40', label: 'Венчание' },
      { time: '14:40 – 16:00', label: 'Поздравление и фотосессия' },
      { time: '16:00 – 17:30', label: 'Первый стол' },
      { time: '17:30 – 18:20', label: 'Перерыв' },
      { time: '18:20 – 19:30', label: 'Второй стол' },
      { time: '19:30 – 19:50', label: 'Перерыв, общение' },
      { time: '19:50 – 20:30', label: 'Окончание торжества' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .timing-widget {
    padding: 12rem 0rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor {
      width: 3rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }

    &__content {
      padding: 4rem 0;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      margin-right: 2rem;

      &:not(:last-child) {
        margin-bottom: 2.8rem;
      }

      &-time {
        width: 12.5rem;
        text-align: center;
        padding: 1.2rem 1.4rem;
        font-size: 1.4rem;
        margin-right: 2rem;
        border: .1rem solid $color-primary;
      }

      &-label {
        font-size: 1.4rem;
        line-height: 2rem;
        display: inline-flex;
      }
    }
  }
</style>
