<template lang="pug">
  .w-telegram.widget.widget--white(id="telegram")
    .w-telegram__content
      img.w-telegram__profile(src="@/assets/img/profile-03.png")
      p.w-telegram__text Для быстрого обмена#[br] информацией, фото и видео#[br] между нашими гостями#[br] мы создали группу#[br] в Telegram
      a.btn(
        target="_blank"
        href="https://t.me/joinchat/ToIaUWYrsWs4MWEy")
        img.icon(src="@/assets/svg/telegram.svg")
        span присоединиться
    made-by-invito
</template>

<script>
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'TelegramWidget',

  components: {
    MadeByInvito
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-telegram {
    overflow: hidden;

    &__content {
      position: relative;
      padding: 15rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &__profile {
      width: 15rem;
      margin-bottom: 5rem;
    }

    &__text {
      margin-bottom: 5rem;
    }
  }
</style>
