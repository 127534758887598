<template lang="pug">
  .w-intro.widget.widget--screen-size
    img.w-intro__cover(src="@/assets/img/main.jpg")
    .w-intro__inner
      img.w-intro__caption(src="@/assets/svg/caption.svg")
</template>

<script>
export default {
  name: 'IntroWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-intro {
    position: relative;
    display: flex;
    z-index: 5;

    &__cover {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__inner {
      position: absolute;
      top: 37.5%;
      width: 100%;
    }

    &__caption {
      width: 80%;
      opacity: 0;
      margin: 0 auto;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }
  }
</style>
