<template lang="pug">
  .w-info.widget
    .w-info__content
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      template(v-if="hasGuests")
        .small-title {{ title }}
        .w-info__subtitle(
          :class="classes") {{ guests }}
        p.w-info__subsubtitle С радостью приглашаем {{ you }} на #[br] нашу свадьбу, которая состоится:
      img.w-info__calendar(src="@/assets/svg/calendar.svg")
      template(v-if="hasGuests")
        p.mb-3 Для вашего удобства, разместили #[br] информацию про наш праздник #[br] на этом сайте, а ещё поделились #[br] частичкой лавстори.#[br]
        p Листайте дальше :)
</template>

<script>
export default {
  name: 'InfoWidget',

  props: {
    invitation: Object
  },

  data: () => ({
    hasCyrilic: false
  }),

  computed: {
    title () {
      if (this.invitation && this.invitation.texts.caption) {
        switch (this.invitation.texts.caption) {
          case 'dear':
            return 'Дорогие'
          case 'dear_boy':
            return 'Дорогой'
          case 'dear_girl':
            return 'Дорогая'
          default:
            return this.invitation.texts.caption
        }
      }
      return 'Дорогие'
    },

    hasGuests () {
      return this.invitation &&
        this.invitation.guests.length
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            const index = item.name.indexOf('(')
            if (index !== -1) {
              return item.name.substring(0, index - 1)
            }
            return item.name
          })
          .join(', ')
          .replace(/,(?=[^,]*$)/, ' и')
      }
      return '-'
    },

    you () {
      if (this.invitation && this.invitation.texts.you) {
        switch (this.invitation.texts.you) {
          case 'you':
            return 'тебя'
          case 'you_2':
            return 'вас'
          default:
            return this.invitation.texts.you
        }
      }
      return 'вас'
    },

    classes () {
      return {
        'w-info__subtitle--fallback': this.hasCyrilic
      }
    }
  },

  watch: {
    guests (value) {
      this.hasCyrilic = /[а-яА-ЯЁё]/gi.test(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-info {
    padding-top: 8rem;
    padding-bottom: 10rem;

    &__decor {
      width: 3rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;
    }

    &__subtitle {
      font-size: 2.5rem;
      display: inline-block;
      text-align: center;
      min-width: 27rem;
      margin-top: 3rem;
      line-height: 1.4;
      padding-bottom: 2.5rem;
      margin-bottom: 3.2rem;
      border-bottom: 1px solid rgba($color-primary, .6);
    }

    &__subtitle--fallback {
      font-size: 2.8em;
      font-family: "Prata" !important;
    }

    &__subsubtitle {
      margin-bottom: 5rem;
    }

    &__calendar {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 4.5rem;
    }
  }
</style>
