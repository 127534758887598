<template lang="pug">
  .w-present.widget
    img.w-present__icon(src="@/assets/svg/present.svg")
    p.w-present__paragrapth Мы не хотим утруждать вас#[br] выбором подарка.
    p.w-present__paragrapth Ваши пожелания в конвертах#[br] помогут нам осуществить мечту#[br] о свадебном путешествии.
    p.w-present__paragrapth Если вам удобнее сделать перевод#[br] на карту, нажмите кнопку:
    v-dropdown
      button.btn.btn--link сделать подарок
      template(slot="content")
        .w-present__info
          p.w-present__info-title Альфа Банк
          p.w-present__info-label MIKHAIL KALATSKI
          p.w-present__info-label 4585 2200 1013 1794
          p.w-present__info-label BY55ALFA301430JGBN0010270000
</template>

<script>
export default {
  name: 'InfoWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-present {
    padding: 10.5rem 2.4rem;
    text-align: center;
    background-color: rgba($color-secondary, .4);

    &__icon {
      display: inline-block;
      margin-bottom: 4.5rem;
      width: 4rem;
    }

    &__paragrapth {
      margin-bottom: 3.2rem;
    }

    &__info {
      padding: 1.5rem;
      font-size: 1.6rem;
      border-radius: .8rem;
      background-color: $color-white;
      box-shadow: 0 0 18px rgba(0, 0, 0, .05);

      &-title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }

      &-label {
        user-select: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
</style>
