<template lang="pug">
  .w-address.widget
    .w-address__content
      img.w-address__decor(src="@/assets/illustrations/flower-branch.svg")
      .title ВЕНЧАНИЕ
      p.w-address__text Лагерь “Солнечний круг”#[br] Начало в 14:00
      a.w-address__link(
        target="_blank"
        href="https://yandex.com/navi/-/CCUuEDeWGA") посмотреть на карте
      img.w-address__decor(src="@/assets/illustrations/flower-branch.svg")
      .title Банкет
      p.w-address__text Лагерь “Солнечний круг”#[br] Начало в 16:00
      a.w-address__link(
        target="_blank"
        href="https://yandex.com/navi/-/CCUuEDeWGA") посмотреть на карте
</template>

<script>
export default {
  name: 'AddressWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;

    &__decor {
      width: 3rem;
      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__text {
      margin-top: 1.6rem;
      margin-bottom: 2rem;
      line-height: 2.4rem;
    }

    &__link {
      position: relative;
      display: inline-block;
      color: $color-primary;
      font-size: 1.2rem;
      letter-spacing: 5%;
      padding: 1.2rem 2.4rem;
      border: .1rem solid rgba($color-primary, .4);
      background-color: rgba($color-secondary, .4);
      text-decoration: none;
      text-transform: none;
      transition: .1s;

      &::before {
        content: "";
        position: absolute;
        top: .6rem;
        left: .6rem;
        width: 100%;
        height: 100%;
        border: .1rem solid rgba($color-primary, .3);
      }

      &:active {
        transform: scale(.97);
        background-color: rgba($color-secondary, 1);
      }

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 6.4rem;
      }
    }

    &__content {
      padding: 12rem 0rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
</style>
